import React, { Component } from "react";

class AboutSection extends Component {
  render() {
    return (
      <>
        <div className="page-wrapper">
          {/* <div className="preloader"></div> */}

          <section className="about-section">
            <div className="auto-container">
              <div className="row clearfix">
                {/* <!--Text Column--> */}
                <div className="text-column col-md-6 col-sm-12 col-xs-12">
                  {/* <!--Section Title---> */}
                  <div
                    className="sec-title wow fadeInDown"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <h2>
                      About TAJ <br />
                      ENTERPRISE
                    </h2>
                  </div>

                  <div className="text">
                    <p>
                      At TAJ ENTERPRISE, we take pride in being a dynamic and
                      forward-thinking company committed to transforming ideas
                      into extraordinary spaces.
                    </p>
                    <p>
                      Our comprehensive suite of services encompasses
                      Architecture, Infrastruture, Interior Design, and
                      Renewable Energy solutions.
                    </p>
                  </div>

                  <a
                    href="/About"
                    className="theme-btn btn-style-two lined-btn-right"
                  >
                    learn more
                  </a>
                </div>

                {/* <!--Image Column--> */}
                <div className="image-column col-md-6 col-sm-12 col-xs-12">
                  <div
                    className="inner-box wow fadeInRight"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="hanged-text">
                      <h5>who we are</h5>
                    </div>
                    <figure className="image-box">
                      <a
                        href="./../images/background/office.jpg"
                        className="lightbox-image"
                        title="Image Caption Here"
                      >
                        <img
                          src={require("./../images/background/office.jpg")}
                          alt=""
                        />
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default AboutSection;
