import React from "react";
import Header from "./../Components/Header";
import BackgroundImage from "./../images/gallery/111.jpg";

import Footer from "./../Components/Footer";
// import HeroBackgroundImage from "./../images/background/test.jpg";
import { FaBuilding, FaHome, FaLeaf } from "react-icons/fa";
import Gallery from "../Components/Gallery";

class Service extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <section
          class="page-title"
          style={{
            backgroundImage: `url(${BackgroundImage})`,
          }}
        >
          <div class="auto-container">
            <h1>Our Services</h1>
          </div>
        </section>

        <section className="services-style-one extended">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="image-column col-md-6 col-sm-12 col-xs-12">
                <div className="inner-box">
                  <figure
                    className="image-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <a
                      href="images/resource/featured-image-9.jpg"
                      className="lightbox-image"
                      title="Image Caption Here"
                    >
                      <img
                        src={require("./../images/resource/featured-image-9.jpg")}
                        alt=""
                      />
                    </a>
                  </figure>
                </div>
              </div>

              <div className="text-column col-md-6 col-sm-12 col-xs-12">
                <div className="sec-title">
                  <h2>Our Services</h2>
                </div>

                <div className="text">
                  <p>
                    We bring your vision to life through a trifecta of
                    expertise: Architecture, where form meets function in
                    structural brilliance; Interior Design, crafting spaces that
                    resonate with your style; and Renewable Energy, seamlessly
                    integrating sustainable solutions for a harmonious and
                    eco-friendly living environment.
                  </p>
                </div>

                <a
                  href="/Contact"
                  className="theme-btn btn-style-two lined-btn-right"
                >
                  Get a Quote
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="services-two no-padd-top">
          <div className="auto-container">
            <div className="row clearfix">
              {/* <!--Default Service Block--> */}
              <div className="default-service-block col-md-4 col-sm-6 col-xs-12">
                <div className="inner-box">
                  <div className="icon-box">
                    <FaBuilding />
                  </div>
                  <h3>Architecture</h3>
                  <div className="text">
                    Enhance your space with our Architecture service—innovative
                    design and structural brilliance for a harmonious
                    environment.
                  </div>
                  <a href="#" className="more-link">
                    Read More <span className="icon flaticon-arrows-6"></span>
                  </a>
                </div>
              </div>

              {/* <!--Default Service Block / Active--> */}
              <div className="default-service-block active col-md-4 col-sm-6 col-xs-12">
                <div
                  className="inner-box wow zoomInStable"
                  data-wow-delay="0ms"
                  data-wow-duration="2500ms"
                >
                  <div className="icon-box">
                    <FaHome />
                  </div>
                  <h3>Interior design</h3>
                  <div className="text">
                    Refine your spaces with our bespoke interior design,
                    harmonizing aesthetics and functionality to shape a home
                    that reflects your unique style.
                  </div>
                  <a href="#" className="more-link">
                    Read More <span className="icon flaticon-arrows-6"></span>
                  </a>
                </div>
              </div>

              {/* <!--Default Service Block--> */}
              <div className="default-service-block col-md-4 col-sm-6 col-xs-12">
                <div className="inner-box">
                  <div className="icon-box">
                    {" "}
                    <FaLeaf />
                  </div>

                  <h3>Renewable Energy</h3>
                  <div className="text">
                    Discover sustainable elegance in our Renewable Energy
                    service, seamlessly merging aesthetics with environmental
                    consciousness.
                  </div>
                  <a href="#" className="more-link">
                    Read More <span className="icon flaticon-arrows-6"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Gallery />
        <section className="call-to-action">
          <div className="auto-container">
            <h2>Architecture is the learned game</h2>
            <a
              href="/Contact"
              className="theme-btn btn-style-three lined-btn-right"
            >
              Contact Us
            </a>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Service;
