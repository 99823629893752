import React from "react";
import Header from "./../Components/Header";
import HeroBackgroundImage3 from "./../images/background/wall.jpg";
import Footer from "./../Components/Footer";
import { FaMapMarker, FaPhone, FaEnvelope } from "react-icons/fa";

class Contact extends React.Component {
  render() {
    return (
      <div>
        <Header />

        {/* <!--Page Title--> */}
        <section
          className="page-title extended-banner"
          style={{
            backgroundImage: `url(${HeroBackgroundImage3})`,
          }}
        >
          <div className="auto-container">
            <h1>Contact us</h1>
          </div>
        </section>

        {/* <!--Contact Section--> */}
        <section className="contact-style-three">
          <div className="auto-container">
            <div className="info-style-two">
              <div className="row clearfix">
                <div className="info-column col-md-4 col-sm-4 col-xs-12">
                  <div className="inner">
                    <div className="icon-box">
                      <FaMapMarker />
                    </div>
                    <strong>Meet Us</strong>
                    Chandpur bazar <br />
                    Rajarhat, Kolkata - 700135.
                  </div>
                </div>

                <div className="info-column col-md-4 col-sm-4 col-xs-12">
                  <div className="inner">
                    <div className="icon-box">
                      <FaPhone />
                    </div>
                    <strong>Call Us</strong>
                    Phone : +(91) 7980014577
                  </div>
                </div>

                <div className="info-column col-md-4 col-sm-4 col-xs-12">
                  <div className="inner">
                    <div className="icon-box">
                      <FaEnvelope />
                    </div>
                    <strong>Mail Us</strong>
                    taj1303@gmail.com
                  </div>
                </div>
              </div>
            </div>

            <div className="row clearfix">
              <div className="form-column col-md-7 col-sm-12 col-xs-12">
                <div className="default-form">
                  <h2>Get in touch with us</h2>

                  <form method="post" action="sendemail.php" id="contact-form">
                    <div className="row clearfix">
                      <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <input
                          type="text"
                          name="username"
                          value=""
                          placeholder="YOUR NAME *"
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <input
                          type="email"
                          name="email"
                          value=""
                          placeholder="YOUR EMAIL *"
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <input
                          type="text"
                          name="phone"
                          value=""
                          placeholder="PHONE *"
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <input
                          type="text"
                          name="subject"
                          value=""
                          placeholder="SUBJECT *"
                        />
                      </div>

                      <div className="form-group col-lg-12 col-sm-12 col-xs-12">
                        <textarea
                          name="message"
                          placeholder="YOUR MESSAGE"
                        ></textarea>
                      </div>
                    </div>
                    <div className="padd-top-20">
                      <button type="submit" className="theme-btn btn-style-one">
                        send message
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="map-column col-md-5 col-sm-12 col-xs-12">
                <div
                  className="map-canvas"
                  data-zoom="14"
                  // data-lat="-37.817085"
                  // data-lng="144.955631"
                  data-type="roadmap"
                  data-hue="#ffc400"
                  data-title="Map"
                  data-content="Chandpur Bazar, Rajarhat, Kolkata 700135<br/><a href='mailto: taj1303@gmail.com'></a>"
                  style={{ height: "520px" }}
                >
                  <a
                    target="_blank"
                    href="https://www.google.com/maps/place/%E0%A6%97%E0%A6%AE+%E0%A6%B8%E0%A7%87%E0%A6%A8%E0%A7%8D%E0%A6%9F%E0%A6%BE%E0%A6%B0/@22.6105112,88.5198859,20z/data=!4m6!3m5!1s0x3a020bbeb93955cb:0x953f34fa43f13785!8m2!3d22.6105105!4d88.5200852!16s%2Fg%2F11qp27vps0?entry=ttu"
                  >
                    <img
                      src={require("./../images/location1 (2).png")}
                      alt="map location"
                    />
                  </a>
                  {/* <div
                    className="map-canvas"
                    data-zoom="14"
                    // data-lat="-37.817085"
                    // data-lng="144.955631"
                    data-type="roadmap"
                    data-hue="#ffc400"
                    data-title="Map"
                    data-content="Chandpur Bazar, Rajarhat, Kolkata 700135<br/><a href='mailto: taj1303@gmail.com'></a>"
                    style={{ height: "520px" }}
                  ></div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Contact;
