import React, { Component } from "react";

class Gallery extends Component {
  render() {
    return (
      <>
        <section className="gallery-section fullwidth style-one">
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2>Our work</h2>
            </div>
          </div>

          <div className="sortable-masonry">
            <div className="auto-container">
              <div className="filters text-center">
                <ul className="filter-tabs filter-btns clearfix">
                  <li
                    className="active filter"
                    data-role="button"
                    data-filter=".all"
                  >
                    <span className="txt">all projects</span>
                  </li>
                  <li
                    className="filter"
                    data-role="button"
                    data-filter=".exterior"
                  >
                    <span className="txt">Exterior design</span>
                  </li>
                  <li
                    className="filter"
                    data-role="button"
                    data-filter=".interior"
                  >
                    <span className="txt">interior design</span>
                  </li>
                  <li
                    className="filter"
                    data-role="button"
                    data-filter=".landscape"
                  >
                    <span className="txt">Architecture</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="items-container clearfix">
              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 default-portfolio-item masonry-item all exterior">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("./../images/gallery/Work image/work10.jpg")}
                      alt=""
                    />
                  </figure>

                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <div className="content">
                        <a href="#" className="cat-link">
                          living room
                        </a>
                        <h3>
                          <a href="portfolio-single.html">Taj Enterprise</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <a
                    href="images/gallery/image-1.jpg"
                    className="image-link lightbox-image"
                    title="Image Caption Here"
                  >
                    <span className="icon flaticon-cross"></span>
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 default-portfolio-item masonry-item all interior landscape">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("./../images/gallery/Work image/work..jpg")}
                      alt=""
                    />
                  </figure>

                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <div className="content">
                        <a href="#" className="cat-link">
                          office
                        </a>
                        <h3>
                          <a href="portfolio-single.html">Taj Enterprise</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <a
                    href="images/gallery/image-2.jpg"
                    className="image-link lightbox-image"
                    title="Image Caption Here"
                  >
                    <span className="icon flaticon-cross"></span>
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 default-portfolio-item masonry-item all interior">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("./../images/gallery/Work image/work4.jpg")}
                      alt=""
                    />
                  </figure>

                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <div className="content">
                        <a href="#" className="cat-link">
                          living room
                        </a>
                        <h3>
                          <a href="portfolio-single.html">Taj Enterprise</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <a
                    href="images/gallery/image-3.jpg"
                    className="image-link lightbox-image"
                    title="Image Caption Here"
                  >
                    <span className="icon flaticon-cross"></span>
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 default-portfolio-item masonry-item all exterior">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("./../images/gallery/Work image/work5.jpg")}
                      alt=""
                    />
                  </figure>

                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <div className="content">
                        <a href="#" className="cat-link">
                          bedroom
                        </a>
                        <h3>
                          <a href="portfolio-single.html">Taj Enterprise</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <a
                    href="images/gallery/image-4.jpg"
                    className="image-link lightbox-image"
                    title="Image Caption Here"
                  >
                    <span className="icon flaticon-cross"></span>
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 default-portfolio-item masonry-item all landscape">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src={require("./../images/gallery/15.jpg")} alt="" />
                  </figure>

                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <div className="content">
                        <a href="#" className="cat-link">
                          living room
                        </a>
                        <h3>
                          <a href="portfolio-single.html">Taj Enterprise</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <a
                    href="images/gallery/image-5.jpg"
                    className="image-link lightbox-image"
                    title="Image Caption Here"
                  >
                    <span className="icon flaticon-cross"></span>
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 default-portfolio-item masonry-item all interior">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("./../images/gallery/Work image/work9.jpg")}
                      alt=""
                    />
                  </figure>

                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <div className="content">
                        <a href="#" className="cat-link">
                          office
                        </a>
                        <h3>
                          <a href="portfolio-single.html">Taj Enterprise</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <a
                    href="images/gallery/image-6.jpg"
                    className="image-link lightbox-image"
                    title="Image Caption Here"
                  >
                    <span className="icon flaticon-cross"></span>
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 default-portfolio-item masonry-item all landscape">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("./../images/gallery/Work image/work2.jpg")}
                      alt=""
                    />
                  </figure>

                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <div className="content">
                        <a href="#" className="cat-link">
                          kitchen
                        </a>
                        <h3>
                          <a href="portfolio-single.html">Taj Enterprise</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <a
                    href="images/gallery/image-5.jpg"
                    className="image-link lightbox-image"
                    title="Image Caption Here"
                  >
                    <span className="icon flaticon-cross"></span>
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 default-portfolio-item masonry-item all landscape">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src={require("./../images/gallery/Work image/work7.jpg")}
                      alt=""
                    />
                  </figure>

                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <div className="content">
                        <a href="#" className="cat-link">
                          office
                        </a>
                        <h3>
                          <a href="portfolio-single.html">Taj Enterprise</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <a
                    href="images/gallery/image-5.jpg"
                    className="image-link lightbox-image"
                    title="Image Caption Here"
                  >
                    <span className="icon flaticon-cross"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Gallery;
