import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./../App.css";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isScrolled: false,
      isMobileMenusVisible: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 0) {
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  }

  showMobileMenus = () => {
    this.setState({
      isMobileMenusVisible: true,
    });
  };
  hideMobileMenus = () => {
    this.setState({
      isMobileMenusVisible: false,
    });
  };
  render() {
    const { isScrolled } = this.state;
    return (
      <>
        <div className={`page-wrapper ${isScrolled ? "fixed-header" : ""}`}>
          {/* <div className="preloader"></div> */}

          {/* <Main Header /> */}
          <header className="main-header">
            {/* <Header Top /> */}
            <div className="header-top">
              <div className="auto-container">
                <div className="row clearfix">
                  {/* <Top Left /> */}
                  <div className="top-left col-md-9 col-sm-9 col-xs-12">
                    <ul>
                      <li>
                        <a href="#">
                          <span className="icon flaticon-cellphone"></span>{" "}
                          (+91) 7980014577
                        </a>
                      </li>
                      <li>
                        <a href="mailto:gardenia@mail.com">
                          <span className="icon flaticon-envelope-1"></span>{" "}
                          taj1303@gmail.com
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* <Top Right /> */}
                  <div className="top-right col-md-3 col-sm-3 col-xs-12">
                    <div className="link-box">
                      <a href="/Contact" className="theme-btn">
                        get a quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Header Top End />

        <Header Lower /> */}
            <div className="header-lower">
              <div className="auto-container">
                {/* <Main Box /> */}
                <div className="main-box">
                  <div className="outer-container clearfix">
                    {/* <Logo Box /> */}
                    <div className="logo-box">
                      <div className="logo">
                        {/* <a href="index.html">
                          <img src={require("./../images/2.png")} alt="" />
                        </a> */}
                        <Link to="/">
                          <img src={require("./../images/2.png")} alt="" />
                        </Link>
                      </div>
                      {/* <div class="logo"></div> */}
                    </div>

                    {/* <Nav Outer /> */}
                    <div className="nav-outer clearfix">
                      {/* <Main Menu /> */}
                      <nav className="main-menu">
                        <div className="navbar-header">
                          {/* <Toggle Button /> */}
                          <button
                            type="button"
                            className="navbar-toggle"
                            data-toggle="collapse"
                            data-target=".navbar-collapse"
                          >
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                          </button>
                        </div>

                        <div className="navbar-collapse collapse clearfix">
                          <ul className="navigation clearfix">
                            <li>
                              <a href="/">Home</a>
                              <ul>
                                <li>
                                  <a href="/">Homepage </a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="/about">About</a>
                              <ul>
                                <li>
                                  <a href="/about">About</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="/service">Our Services</a>
                              <ul>
                                <li>
                                  <a href="/service">Services</a>
                                </li>
                              </ul>
                            </li>
                            {/* <li>
                              <a href="/projects">Projects</a>
                              <ul>
                                <li>
                                  <a href="/projects">Projects</a>
                                </li>
                              </ul>
                            </li> */}
                            {/* <li>
                              <a href="#">Blog</a>
                              <ul>
                                <li>
                                  <a href="blog.html">Our Blog</a>
                                </li>
                              </ul>
                            </li> */}
                            <li>
                              <a href="/contact">Contact Us</a>
                              <ul>
                                <li>
                                  <a href="/contact">Contact </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </nav>
                      {/* <Main Menu End /> */}
                    </div>
                    {/* <Nav Outer End />

                <Hidden Nav Toggler /> */}
                    <div className="nav-toggler">
                      <button
                        className="hidden-bar-opener"
                        onClick={this.showMobileMenus}
                      >
                        <span className="icon fa fa-bars"></span>
                      </button>
                    </div>
                    {/* <Hidden Nav Toggler /> */}
                  </div>
                </div>
              </div>
            </div>
          </header>

          {/* <Hidden Navigation Bar /> */}
          {this.state.isMobileMenusVisible && (
            <section className="hidden-bar right-align visible-sidebar">
              <div className="hidden-bar-closer">
                <button className="btn" onClick={this.hideMobileMenus}>
                  <i className="fa fa-close"></i>
                </button>
              </div>

              {/* <Hidden Bar Wrapper /> */}
              <div className="hidden-bar-wrapper">
                <logo />
                <div className="logo text-center">
                  {/* <a href="index.html">
                    <img src={require("./../images/logo-4.png")} alt="" />
                  </a> */}
                  <Link to="/">
                    <img src={require("./../images/2.png")} alt="" />
                  </Link>
                </div>
                <logo />

                {/* <Side-menu /> */}
                <div className="side-menu">
                  <navigation />
                  <ul className="navigation">
                    <li>
                      <Link to="/">Home</Link>
                    </li>

                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/service">Our Service</Link>
                    </li>

                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                {/* <Side-menu /> */}
              </div>
            </section>
          )}
        </div>
      </>
    );
  }
}

export default Header;
