import React from "react";
import Header from "./../Components/Header";

import Footer from "./../Components/Footer";

class Projects extends React.Component {
  render() {
    return (
      <div>
        <Header />

        <Footer />
      </div>
    );
  }
}

export default Projects;
