import React, { Component } from "react";
import { FaMapMarker, FaPhone, FaEnvelope, FaClock } from "react-icons/fa";

class Footer extends Component {
  render() {
    return (
      <>
        <div className="page-wrapper">
          {/* <div className="preloader"></div> */}

          <footer className="main-footer">
            <div className="auto-container">
              <div className="info-section">
                <div className="row clearfix">
                  <div className="info-column col-md-3 col-sm-6 col-xs-12">
                    <div className="inner-box">
                      <div className="icon-box">
                        <FaMapMarker />
                      </div>
                      <ul>
                        <li>Chandpur Bazar, Rajarhat, Kolkata - 700135</li>
                      </ul>
                    </div>
                  </div>

                  <div className="info-column col-md-3 col-sm-6 col-xs-12">
                    <div className="inner-box">
                      <div className="icon-box">
                        <FaPhone />
                      </div>
                      <ul>
                        <li>(+91) 7980014577</li>
                      </ul>
                    </div>
                  </div>

                  <div className="info-column col-md-3 col-sm-6 col-xs-12">
                    <div className="inner-box">
                      <div className="icon-box">
                        <FaEnvelope />
                      </div>
                      <ul>
                        <li>taj1303@gmail.com</li>
                      </ul>
                    </div>
                  </div>

                  <div className="info-column col-md-3 col-sm-6 col-xs-12">
                    <div className="inner-box">
                      <div className="icon-box">
                        <FaClock />
                      </div>
                      <ul>
                        <li>Mon - Fri : 8:00 AM - 8:00PM</li>
                        <li>Sat - Sun 10:00 AM - 6:00PM</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="widgets-section">
                <div className="row clearfix">
                  <div className="big-column col-md-6 col-sm-12 col-xs-12">
                    <div className="row clearfix">
                      <div className="footer-column col-md-6 col-sm-6 col-xs-12">
                        <div className="footer-widget about-widget">
                          <div className="footer-logo">
                            <a href="index.html">
                              <img
                                src={require("./../images/2white (1).png")}
                                alt=""
                              />
                            </a>
                          </div>
                          <br />

                          <div className="widget-content">
                            <div className="text">
                              In our vision for the future, we aspire to see a
                              world where innovative design harmoniously
                              integrates with renewable energy.
                            </div>

                            <div className="social-links">
                              <a href="#">
                                <span className="fa fa-twitter"></span>
                              </a>
                              <a href="#">
                                <span className="fa fa-facebook-square"></span>
                              </a>
                              <a href="#">
                                <span className="fa fa-linkedin-square"></span>
                              </a>
                              <a href="#">
                                <span className="fa fa-skype"></span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="footer-column col-md-6 col-sm-6 col-xs-12">
                        <div className="footer-widget links-widget">
                          <h2>Our Services</h2>
                          <div className="widget-content">
                            <ul className="list">
                              <li>
                                <a href="#">Architecture</a>
                              </li>

                              <li>
                                <a href="#">Interior Design</a>
                              </li>
                              <li>
                                <a href="#">Renewable Energy</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="big-column col-md-6 col-sm-12 col-xs-12">
                    <div className="row clearfix">
                      <div className="footer-column col-md-6 col-sm-6 col-xs-12">
                        <div className="footer-widget news-widget">
                          <h2>latest Works</h2>
                          <div className="widget-content">
                            <div className="post">
                              <figure className="post-thumb">
                                <a href="#">
                                  <img
                                    src={require("./../images/resource/work..jpg")}
                                    alt=""
                                  />
                                </a>
                              </figure>
                              <h4>
                                <a href="#">Office Elegance Unveiled</a>
                              </h4>
                              <div className="time">June 10, 2022</div>
                            </div>

                            <div className="post">
                              <figure className="post-thumb">
                                <a href="#">
                                  <img
                                    src={require("./../images/resource/work12.jpg")}
                                    alt=""
                                  />
                                </a>
                              </figure>
                              <h4>
                                <a href="#">Latest Apartment Masterpiece</a>
                              </h4>
                              <div className="time">August 21, 2023</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="footer-column col-md-6 col-sm-6 col-xs-12">
                        <div className="footer-widget newsletter-widget">
                          <h2>Email address</h2>
                          <div className="widget-content">
                            <div className="newsletter-form">
                              <form method="post" action="contact.html">
                                <div className="form-group">
                                  <input
                                    type="email"
                                    name="email"
                                    value=""
                                    placeholder="EMAIL ADDRESS"
                                    required
                                  />
                                </div>
                                <button type="submit" className="theme-btn">
                                  subscribe now
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-bottom">
                <div className="row clearfix">
                  <div className="col-lg-5 col-md-12 col-xs-12">
                    <div className="copyright-text">
                      &copy; 2022 All Rights Reserved
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12 col-xs-12">
                    <nav className="footer-nav clearfix">
                      <ul className="pull-right clearfix">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/service">Services</a>
                        </li>
                        <li>
                          <a href="/">Terms &amp; condition</a>
                        </li>
                        <li>
                          <a href="#">Privacy policy</a>
                        </li>
                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          />
        </div>
      </>
    );
  }
}

export default Footer;
