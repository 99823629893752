import React from "react";
import Header from "./../Components/Header";
import AboutSection from "./../Components/AboutSection";
import Footer from "./../Components/Footer";
import HeroBackgroundImage2 from "./../images/background/bg2.jpg";
import HeroBackgroundImage1 from "./../images/background/new.jpg";

class About extends React.Component {
  render() {
    return (
      <div>
        <Header />

        {/* <!--Page Title--> */}
        <section
          className="page-title"
          style={{
            backgroundImage: `url(${HeroBackgroundImage2})`,
          }}
        >
          <div className="auto-container">
            <h1>About Us</h1>
          </div>
        </section>

        <AboutSection />

        {/* <!--Parallax Style One--> */}
        <section
          class="parallax-style-one"
          style={{
            backgroundImage: `url(${HeroBackgroundImage1})`,
          }}
        >
          <div class="auto-container">
            <h2>
              Architecture is basically the design of interiors, the art of
              organizing interior space
            </h2>
            <a href="/Contact" class="theme-btn btn-style-one">
              Contact Us
            </a>{" "}
            <a href="/Contact" class="theme-btn btn-style-three">
              Purchase Now
            </a>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default About;
