import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./Styles/bootstrap.css";
import "./Styles/revolution-slider.css";
import "./Styles/style.css";
import "./Styles/responsive.css";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Services from "./Components/Services";
import Service from "./Pages/Service";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Gallery from "./Components/Gallery";
import AboutSection from "./Components/AboutSection";
import Home from "./Pages/Home";
import Projects from "./Pages/Projects";
import Contact2 from "./Pages/Contact2";
// import HeroBackgroundImage from "./images/background/transparent-building-image.png";

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/service" element={<Service />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/header" element={<Header />} />
          <Route exact path="/footer" element={<Footer />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/aboutsection" element={<AboutSection />} />
          <Route exact path="/contact2" element={<Contact2 />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
