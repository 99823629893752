import React, { Component } from "react";

class Services extends Component {
  render() {
    return (
      <>
        <div className="page-wrapper">
          {/* <div className="preloader"></div> */}

          <section className="services-style-one">
            <div className="auto-container">
              <div className="row clearfix">
                {/* <!--Image Column--> */}
                <div className="image-column col-md-6 col-sm-12 col-xs-12">
                  <div className="inner-box">
                    <figure
                      className="image-box wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <a
                        href="images/resource/featured-image-9.jpg"
                        className="lightbox-image"
                        title="Image Caption Here"
                      >
                        <img
                          src={require("./../images/resource/featured-image-9.jpg")}
                          alt=""
                        />
                      </a>
                    </figure>
                  </div>
                </div>

                {/* <!--Text Column--> */}
                <div className="text-column col-md-6 col-sm-12 col-xs-12">
                  {/* <!--Section Title---> */}
                  <div className="sec-title">
                    <h2>Our Services</h2>
                  </div>

                  <div className="text">
                    <p>
                      We bring your vision to life through a trifecta of
                      expertise: Architecture, where form meets function in
                      structural brilliance; Interior Design, crafting spaces
                      that resonate with your style; and Renewable Energy,
                      seamlessly integrating sustainable solutions for a
                      harmonious and eco-friendly living environment.
                    </p>
                  </div>

                  <a
                    href="/Contact"
                    className="theme-btn btn-style-two lined-btn-right"
                  >
                    Get a Quote
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Services;
