import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./../Styles/home-slider.css";

const HomeMainSlider = () => {
  return (
    <Carousel autoPlay infiniteLoop showThumbs={false}>
      <div>
        <img
          src="https://cdn.pixabay.com/photo/2017/08/05/12/08/living-room-2583032_1280.jpg"
          alt="Slide 1"
        />
        <p className="legend">
          Welcome to <br />
          Taj Enterprise
        </p>
      </div>
      <div>
        <img
          src="https://cdn.pixabay.com/photo/2017/09/20/11/53/architecture-2768284_1280.jpg"
          alt="Slide 2"
        />
        <p className="legend">
          We are called to be <br /> Architects of the future
        </p>
      </div>
      <div>
        <img
          src="https://cdn.pixabay.com/photo/2014/08/11/21/32/fireplace-416042_1280.jpg"
          alt="Slide 3"
        />
        <p className="legend">We build your dream</p>
      </div>
    </Carousel>
  );
};

export default HomeMainSlider;
