import React, { Component } from "react";
import HeroBackgroundImage from "./../images/background/test.jpg";
import Header from "../Components/Header";
import AboutSection from "../Components/AboutSection";
import Services from "../Components/Services";
import Gallery from "../Components/Gallery";
import Footer from "../Components/Footer";
import HomeMainSlider from "./../Components/HomeMainSlider";
import { FaBuilding, FaHome, FaLeaf } from "react-icons/fa";

class Home extends Component {
  render() {
    return (
      <>
        <div className="page-wrapper">
          {/* <div className="preloader"></div> */}

          <Header />
          {/* Main Slider */}
          <HomeMainSlider />
          <AboutSection />

          {/* <!--Fluid Section One--> */}
          <section className="fluid-section-one">
            <div className="outer-box clearfix">
              {/* <!--Text Column--> */}
              <div className="text-column clearfix">
                <div
                  className="inner-box wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  {/* <!--Section Title---> */}
                  <div className="sec-title">
                    <h2>OUR MISSION</h2>
                  </div>

                  <div className="text">
                    <p>
                      Our mission is to pioneer a new era of design and
                      construction that harmonizes aesthetics, functionality,
                      and environmental responsibility. We aspire to be leaders
                      in shaping spaces that not only meet the needs of today
                      but also stand as beacons of inspiration for generations
                      to come.
                    </p>
                  </div>

                  <a
                    href="/Contact"
                    className="theme-btn btn-style-three lined-btn-right"
                  >
                    Contact us
                  </a>
                </div>
              </div>

              {/* <!--Image Column--> */}
              <div
                className="image-column"
                style={{
                  backgroundImage: `url(${HeroBackgroundImage})`,
                }}
              ></div>

              {/* <!--Rotated Layer--> */}
              <div className="rotated-layer-one"></div>
            </div>
          </section>
          <Services />
          {/* <!--Services Two--> */}
          <section className="services-two no-padd-top">
            <div className="auto-container">
              <div className="row clearfix">
                {/* <!--Default Service Block--> */}
                <div className="default-service-block col-md-4 col-sm-6 col-xs-12">
                  <div className="inner-box">
                    <div className="icon-box">
                      <FaBuilding />
                    </div>
                    <h3>Architecture</h3>
                    <div className="text">
                      Enhance your space with our Architecture
                      service—innovative design and structural brilliance for a
                      harmonious environment.
                    </div>
                    <a href="/Service" className="more-link">
                      Read More <span className="icon flaticon-arrows-6"></span>
                    </a>
                  </div>
                </div>

                {/* <!--Default Service Block / Active--> */}
                <div className="default-service-block active col-md-4 col-sm-6 col-xs-12">
                  <div
                    className="inner-box wow zoomInStable"
                    data-wow-delay="0ms"
                    data-wow-duration="2500ms"
                  >
                    <div className="icon-box">
                      <FaHome />
                    </div>
                    <h3>Interior design</h3>
                    <div className="text">
                      Refine your spaces with our bespoke interior design,
                      harmonizing aesthetics and functionality to shape a home
                      that reflects your unique style.
                    </div>
                    <a href="/Service" className="more-link">
                      Read More <span className="icon flaticon-arrows-6"></span>
                    </a>
                  </div>
                </div>

                {/* <!--Default Service Block--> */}
                <div className="default-service-block col-md-4 col-sm-6 col-xs-12">
                  <div className="inner-box">
                    <div className="icon-box">
                      {" "}
                      <FaLeaf />
                    </div>

                    <h3>Renewable Energy</h3>
                    <div className="text">
                      Discover sustainable elegance in our Renewable Energy
                      service, seamlessly merging aesthetics with environmental
                      consciousness.
                    </div>
                    <a href="/Service" className="more-link">
                      Read More <span className="icon flaticon-arrows-6"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Gallery />
          {/* <!--Call To Action Section--> */}
          <section className="call-to-action">
            <div className="auto-container">
              <h2>Architecture is the learned game</h2>
              <a
                href="/Contact"
                className="theme-btn btn-style-three lined-btn-right"
              >
                Contact Us
              </a>
            </div>
          </section>

          {/* <!--BLog Section--> */}
          <section className="blog-section extra-padding">
            <div className="auto-container">
              <div className="sec-title text-center">
                <h2>TEAM UP</h2>
              </div>

              <div className="row clearfix">
                <div className="default-blog-news col-md-6 col-sm-6 col-xs-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <figure className="image-box">
                      <a href="blog-single.html">
                        <img
                          src={require("./../images/gallery/Work image/team.jpg")}
                          alt=""
                        />
                      </a>
                      <a href="blog.html" className="cat-link">
                        Solar Energy
                      </a>
                    </figure>
                    <div className="lower-content">
                      <h3>
                        <a href="blog-single.html">
                          Solar-Powered Style: Our Special Collaboration
                        </a>
                      </h3>
                      <div className="post-meta">
                        By Taj Enterprise & Institute of Solar Technology
                      </div>
                      <div className="link-box">
                        <a
                          href="/Service"
                          className="theme-btn btn-style-two lined-btn-right"
                        >
                          read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="default-blog-news col-md-6 col-sm-6 col-xs-12">
                  <div
                    className="inner-box wow fadeInRight"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <figure className="image-box">
                      <a href="blog-single.html">
                        <img
                          src={require("./../images/gallery/Work image/team1.jpg")}
                          alt=""
                        />
                      </a>
                      <a href="blog.html" className="cat-link">
                        Renewable Energy
                      </a>
                    </figure>
                    <div className="lower-content">
                      <h3>
                        <a href="blog-single.html">
                          Capturing Collaboration: Our Team in Design Harmony
                        </a>
                      </h3>
                      <div className="post-meta">
                        By Taj Enterprise & Institute of solar Technology
                      </div>
                      <div className="link-box">
                        <a
                          href="/Service"
                          className="theme-btn btn-style-two lined-btn-right"
                        >
                          read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

export default Home;
